







































































import { defineComponent } from '@vue/composition-api'
import router from '@/router'
import { PaginationOptions, parseQuery, usePagination } from '@/composables/pagination'
import TopBar from '@/components/organisms/o-top-bar.vue'
import Pagination from '@/components/base/Pagination.vue'
import { mapActions, mapState } from 'vuex'
import { User } from '@/api/interfaces/user'
import { QueryParams } from '@/store/api-plateform-utils'
import { format, parseJSON } from 'date-fns'
import { userCanAccess } from '@/composables/UserGrant'

export default defineComponent({
  setup (props, ctx) {
    const canCreateUser = () => userCanAccess('User Create')
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.userList.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      }: PaginationOptions = usePagination(
      totalItems,
      parseQuery(query),
    ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      canCreateUser,
    }
  },
  name: 'userList',
  components: {
    TopBar,
    Pagination,
  },
  data () {
    return {
      title: this.$t('user_group.title_header'),
      headers: [
        {
          value: 'checkbox',
          sortable: false,
        },
        {
          text: this.$t('user.list.headers.email'),
          align: 'start',
          value: 'email',
        },
        {
          text: this.$t('user.list.headers.firstName'),
          value: 'firstName',
        },
        {
          text: this.$t('user.list.headers.lastName'),
          align: 'start',
          value: 'lastName',
        },
        {
          text: this.$t('user.list.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  created () {
    this.loadUsers(this.baseRequest)
  },
  computed: {
    ...mapState('userList', {
      usersList: 'list',
      loading: 'loading',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'User Edit' || to.name === 'User Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
  methods: {
    ...mapActions('userList', {
      loadUsers: 'load',
    }),
    loadData () {
      this.addParamsToLocation()
      this.loadUsers(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'User List', query: query })
    },
    gotToUserForm (item: User) {
      if (item.id) {
        this.$router.push({
          name: 'User Edit',
          params: { idUser: item.id.toString() },
        })
      }
    },
  },
})
